import React, { useState } from 'react';
import classnames from 'classnames';

import useTracking from 'components/Globals/Analytics';

import { useQuery } from 'utils/react-query';
import times from 'lodash/times';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import SectionBlock from 'components/Globals/SectionBlock';
import AgencyDetailsModal from 'components/Artist/Display/AgencyAndContact/AgencyDetailsModal';
import EntityName from 'components/Globals/EntityName';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Artist/queries';

import {
  TP,
  PRIVACY_ATTRIBUTE_IDS,
  PROFILE_TYPE_IDS,
  PRIVACY_ATTRIBUTE_PROFILE_TYPES,
  ENTITY_TYPE,
  PROFILE_TYPES,
} from 'constants/index';

import { useTranslation } from 'src/i18n';
import { useDialogs } from 'utils/hooks/useDialogs';
import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, CONTACT_NO_ACCESS_IDS } from 'utils/tracking';

import classes from './Agencies.module.scss';

const AgencyList = ({ entity, agencies, isOverview, setSelectedAgency, setSelectedContact, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const track = useTracking();

  const { setIsLoginDialog } = useDialogs();
  const { permissions } = usePageContext();
  const activeProfileTypeId = permissions?.activeProfile?.profileType?.id;
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  const showLoginToView = !permissions?.isLoggedIn && entity?.subscriptionStatus !== PROFILE_TYPES.PRO;

  const onAgencyClickHandler = (hasPermissionToView, agency) => {
    if (showLoginToView) {
      setIsLoginDialog({ isOpen: true });
      return;
    }

    if (!hasPermissionToView) {
      setSelectedContact({
        ...agency,
        privacyAttributes:
          agency?.privacyAttributes?.length > 1
            ? agency?.privacyAttributes?.filter(privacy => privacy?.id !== PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL)
            : agency?.privacyAttributes,
      });

      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          id: CONTACT_NO_ACCESS_IDS.VIEW,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );

      return;
    }

    if (hasPermissionToView) {
      setSelectedAgency(agency);
    }
  };

  return (
    <div>
      {!isOverview && (
        <div className={classes.agencyHeader}>
          <SectionBlock
            title={t('AGENCY_ARTIST_MANAGER')}
            styles={{
              title: classes.agencyHeaderTitle,
              header: classes.agencySectionHeader,
              leftBoxIcon: classes.leftBoxIcon,
            }}
            {...sectionProps}
          />
        </div>
      )}
      <div className={isOverview ? classes.agencies : classes.agencyContent}>
        {agencies.data.map(({ agency }, index) => {
          const agencyInfo = { ...agency?.agency };
          const privacyAttributesIds = agency?.privacyAttributes?.map(privacy => privacy?.id);
          const trackingData = {
            section: SECTIONS.REPRESENTATION_AND_CONTACT,
            component: COMPONENTS.CONTACT,
            entityId: agencyInfo.id,
            entityName: agencyInfo.name,
            entityType: ENTITY_TYPE.MANAGER,
          };
          const hasPermissionToView =
            hasCastingToolAccess ||
            privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_PROFILE_TYPES[activeProfileTypeId]) ||
            privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.PUBLIC) ||
            (privacyAttributesIds?.includes(PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO) &&
              [PROFILE_TYPE_IDS.ARTIST, PROFILE_TYPE_IDS.AGENT, PROFILE_TYPE_IDS.AOS].includes(activeProfileTypeId));

          return (
            <LinkButton
              key={agency?.id}
              variant="text"
              styles={{ root: isOverview ? classes.agencyLink : classes.agencyItem }}
              onClick={() => onAgencyClickHandler(hasPermissionToView, agency)}
              disableUnderline
              trackingData={trackingData}
            >
              <div className={classes.agencyRow}>
                {!isOverview && (
                  <>
                    <SpriteIcon className={classes.agencyIcon} icon="agency" size={24} />
                    <SpriteIcon className={classes.agencyHoverIcon} icon="agency:hover" size={24} />
                  </>
                )}
                <div className={classnames(classes.agencySection, { [classes.agencySectionOverview]: isOverview })}>
                  <div className={classnames(classes.agencyCardInfo, { [classes.agencyOverviewInfo]: isOverview })}>
                    <Typography size={14} weight="medium" className={classes.agencyName}>
                      {hasPermissionToView && !showLoginToView ? (
                        <EntityName
                          entity={agencyInfo}
                          entityType={ENTITY_TYPE.MANAGER}
                          isRaw
                          trackingData={trackingData}
                        />
                      ) : (
                        `${t(`${TP}.M_AGENCY`)} ${index + 1}`
                      )}
                    </Typography>
                    {agency?.isGM && hasPermissionToView && !showLoginToView && (
                      <Typography className={classes.agreement} color="secondary" size="12">{`(${t(
                        `${TP}.FN_GENERAL_MANAGEMENT`,
                      )})`}</Typography>
                    )}
                    {!agency?.acknowledged && hasPermissionToView && !showLoginToView && (
                      <Typography size="12" color="secondary" className={classes.notApproved}>
                        {isOverview ? (
                          <>
                            (<SpriteIcon size={13} icon="report_error" /> {t('AGENCY_NOT_CONFIRMED')})
                          </>
                        ) : (
                          <>
                            <SpriteIcon size={13} icon="report_error" /> {t('AGENCY_NOT_CONFIRMED')}
                          </>
                        )}
                      </Typography>
                    )}
                    {showLoginToView && (
                      <Typography className={classes.loginToView} size={12}>
                        ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
                      </Typography>
                    )}
                  </div>
                  <div className={classnames(classes.ctaSection, { [classes.ctaSectionOverview]: isOverview })}>
                    {!isOverview && (
                      <Typography size={12} color="secondary" className={classes.ctaTitle}>
                        {t('VIEW_CONTACT_REPRESENTATION')}
                      </Typography>
                    )}
                    <SpriteIcon icon="chevron_right" size={16} className={classes.rightIcon} />
                  </div>
                </div>
              </div>
            </LinkButton>
          );
        })}
      </div>
    </div>
  );
};

const Agencies = ({ entity, isOverview, setSelectedContact, isEditMode, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const [selectedAgency, setSelectedAgency] = useState(null);
  const { data: agencies, isLoading } = useQuery(
    queries.getArtistAgencies({
      id: entity?.id,
      privacyAttributes: [
        PRIVACY_ATTRIBUTE_IDS.PUBLIC,
        PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL,
        PRIVACY_ATTRIBUTE_IDS.AGENCY,
        PRIVACY_ATTRIBUTE_IDS.AOS,
        PRIVACY_ATTRIBUTE_IDS.ARTIST,
        PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO,
      ],
      queryConfig: {
        enabled: entity?.stats?.agencies?.exists,
      },
    }),
  );

  if (
    !entity?.stats?.agencies?.exists ||
    (!isLoading && agencies?.data?.length === 0) ||
    !entity?.stats?.customizations?.agency?.visibility
  ) {
    return null;
  }

  return (
    <div>
      {isLoading && entity?.stats?.agencies?.exists && entity?.stats?.customizations?.agency?.visibility && (
        <>
          {times(entity?.agencies?.length, index => (
            <Skeleton key={index} variant="rectangular" height={50} className={classes.skeleton} />
          ))}
        </>
      )}
      {agencies?.data?.length > 0 && (
        <>
          {isEditMode && isOverview && (
            <SectionBlock title={t(`${TP}.FN_REPRESENTATION_AND_CONTACT`)} {...sectionProps} />
          )}

          <AgencyList
            entity={entity}
            agencies={agencies}
            isOverview={isOverview}
            setSelectedAgency={setSelectedAgency}
            setSelectedContact={setSelectedContact}
            sectionProps={sectionProps}
          />
        </>
      )}
      {selectedAgency && <AgencyDetailsModal agency={selectedAgency} onClose={() => setSelectedAgency(null)} />}
    </div>
  );
};

export default Agencies;
