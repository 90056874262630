import React, { useMemo } from 'react';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityCard from 'components/Globals/EntityCard';
import PreviewCard from 'components/Globals/PreviewCard';
import SectionBlock from 'components/Globals/SectionBlock';

import orgQueries from 'containers/Organizations/queries';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import classes from './AssociatedOrgs.module.scss';

const AssociatedOrgs = ({ entity, entityType, limit, enableSeeAll = false, isOverview }) => {
  const { navigate } = usePageContext();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const pageLinkProps = navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.ABOUT });
  const { data: associatedOrgs, isLoading } = useQuery(orgQueries.getAssociatedOrganizations({ id: entity?.id }));
  const associatedOrgsList = useMemo(() => (limit ? associatedOrgs?.data?.slice(0, limit) : associatedOrgs?.data), [
    associatedOrgs?.data,
    limit,
  ]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={45} width={375} />;
  }

  if (associatedOrgs?.data?.length === 0) {
    return null;
  }

  return (
    <SectionBlock
      title={t(`${TP}.FN_ASSOCIATED_ORGS`)}
      linkProps={pageLinkProps}
      count={associatedOrgs?.data?.length}
      enableSeeAll={enableSeeAll}
      seeAllTrackingData={{
        section: SECTIONS.ASSOCIATED_ORGANIZATIONS,
        component: COMPONENTS.SEE_ALL_CTA,
      }}
    >
      <ul className={classes.organizations}>
        {associatedOrgsList?.map(org => {
          const { organization } = org;
          const newTrackingData = {
            section: SECTIONS.ASSOCIATED_ORGANIZATIONS,
            component: COMPONENTS.ORGANIZATION_CARD,
            entityId: organization?.id,
            entityName: organization?.name,
            entityType: ENTITY_TYPE.ORGANIZATION,
          };

          if (isOverview) {
            return (
              <li key={organization?.id} className={classes.organization}>
                <EntityCard
                  entityType={ENTITY_TYPE.ORGANIZATION}
                  entity={organization}
                  typographyProps={{
                    name: {
                      truncate: true,
                    },
                    subtext: {
                      truncate: true,
                    },
                  }}
                  isLinkShallow={false}
                  trackingData={newTrackingData}
                />
                <SpriteIcon className={classes.rightIcon} icon="chevron_right" size={18} />
              </li>
            );
          }

          return (
            <li key={organization?.id}>
              <PreviewCard entityType={ENTITY_TYPE.ORGANIZATION} entity={organization} trackingData={newTrackingData} />
            </li>
          );
        })}
      </ul>
    </SectionBlock>
  );
};

export default AssociatedOrgs;
