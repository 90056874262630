import React, { Fragment, useMemo, useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import find from 'lodash/find';
import head from 'lodash/head';
import PropTypes from 'prop-types';

import Typography from 'components/uiLibrary/Typography';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton

import queries from 'containers/Globals/queries';
import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { useTranslation, i18n } from 'src/i18n';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import classes from './EntityBioSummary.module.scss';
import SectionBlock from '../SectionBlock';

const trackingData = {
  section: SECTIONS.BIOGRAPHY,
};

const EntityBioSummary = ({ entity, entityType, isEditMode, sectionProps = {}, isOverview }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate, mainPath } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const [isTruncated, setIsTruncated] = useState(false);
  const excerptRef = useRef(null);

  const { data: bio, isLoading } = useQuery(
    queries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: { enabled: entity?.stats?.about?.exists && entityType === ENTITY_TYPE.ARTIST },
    }),
  );

  const bioInfo = useMemo(() => {
    const language = typeof window === 'undefined' ? bio?.lang : i18n.language;
    const entityBio =
      find(bio?.data, { language: { lang: language } }) ||
      find(bio?.data, { language: { lang: 'en' } }) ||
      head(bio?.data);

    const subTitle = entityType === ENTITY_TYPE.ARTIST ? `${TP}.m_BIO` : `${TP}.ABOUT`;

    const linkProps = navigate.getLinkProps({
      entity,
      entityType,
      suffix: entityType === ENTITY_TYPE.ARTIST ? ENTITY_MAIN_TABS.BIO : ENTITY_MAIN_TABS.ABOUT,
    });

    return {
      subTitle,
      linkProps,
      excerpt: entityBio?.excerpt,
      language,
    };
  }, [navigate, bio, entity, entityType]);

  const { subTitle, linkProps, excerpt, language } = bioInfo;

  useEffect(() => {
    const { current } = excerptRef;
    if (current) {
      const isOverflowing = current.scrollHeight > current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [excerpt]);

  if (isLoading && entityType === ENTITY_TYPE.ARTIST) {
    return <Skeleton variant="rectangular" height={50} />;
  }

  if (
    (entityType === ENTITY_TYPE.ORGANIZATION && !entity?.description?.length) ||
    (entityType === ENTITY_TYPE.ARTIST && !excerpt?.length)
  ) {
    return null;
  }

  const Wrapper = isOverview ? SectionBlock : Fragment;

  if (!isDesktop && isOverview && !isEditMode) {
    return (
      <Link
        {...linkProps}
        disableUnderline
        disableHover
        styles={{ root: classes.linkWrapper }}
        scroll
        trackingData={trackingData}
      >
        <div className={classnames(classes.title, { [classes.bioOverviewTitle]: isOverview })}>
          <Typography variant="p" weight="regular" color="secondary" size="12" lang={language}>
            <Typography variant="span" weight="medium" size="12" inline color="secondary">
              {`${t(subTitle)} `}
            </Typography>
            {excerpt || entity?.description}
          </Typography>
        </div>
      </Link>
    );
  }

  const languageNames = bio?.data?.map(lang => lang?.language?.name);

  return (
    <Wrapper
      title={t(subTitle)}
      linkProps={linkProps}
      className={classnames(classes.bio, { [classes.bioOverview]: isOverview })}
      {...sectionProps}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <Link
        {...linkProps}
        disableUnderline
        disableHover
        className={classes.linkWrapper}
        scroll
        trackingData={trackingData}
      >
        {mainPath && !isOverview && (
          <Typography weight="medium" size="16" inline className={classes.subTitle}>
            {`${t(subTitle)} `}
          </Typography>
        )}
        <div className={classnames(classes.title, { [classes.bioOverviewTitle]: isOverview })} ref={excerptRef}>
          <Typography variant="p" color="secondary" lang={language}>
            {excerpt}
          </Typography>
          {isTruncated && (
            <Typography color="secondary" className={classes.readMoreLink}>
              ...{t(`${TP}.FN_READ_MORE`)}
            </Typography>
          )}
        </div>
        {languageNames?.length > 1 && (
          <Typography size={12} color="secondary" italic className={classes.bioLanguages}>
            ({t(`${TP}.FN_AVAILABLE_IN`)}: {languageNames?.join(', ')})
          </Typography>
        )}
      </Link>
    </Wrapper>
  );
};

EntityBioSummary.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
};

export default EntityBioSummary;
