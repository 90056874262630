import React, { useMemo } from 'react';

import Modal from 'components/uiLibrary/Modal';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import { TP, CONTACT_TYPE, CONTACT_LABEL, ENTITY_TYPE } from 'constants/index';

import { addMailTolIfNeed, addProtocolIfNeed, isValidHttpUrl } from 'utils/common';

import { useTranslation } from 'src/i18n';

import classes from './ContactDetailsModal.module.scss';

const validContactTypes = [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE, CONTACT_TYPE.ADDRESS];

const getContactTitle = ({ entityType, isBoxOffice }) => {
  if (isBoxOffice) {
    return `${TP}.FN_BOX_OFFICE`;
  }
  return entityType === ENTITY_TYPE.ORGANIZATION
    ? `${TP}.FN_CONTACT_INFO_TITLE`
    : `${TP}.FN_ARTIST_DIRECT_CONTACT_TITLE`;
};

const getLink = contactItem => {
  const { value, href } = contactItem || {};
  return contactItem?.contactType?.id === 1
    ? addMailTolIfNeed(href) || addMailTolIfNeed(value)
    : addProtocolIfNeed(href) || isValidHttpUrl(value);
};

const ContactDetailsModal = ({ entityType, isBoxOffice, contacts, onClose, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const title = getContactTitle({ entityType, isBoxOffice });
  const filteredContacts = useMemo(
    () =>
      Object.values(contacts)?.reduce((result, contactArray) => {
        const contactsWithPermission = contactArray?.filter(contact => contact?.hasPermissionToView);
        const contactTypeSlug = contactArray?.[0]?.contactType?.slug;

        if (validContactTypes.includes(contactTypeSlug) && contactsWithPermission?.length) {
          return { ...result, [contactTypeSlug]: contactsWithPermission };
        }

        return result;
      }, {}),
    [contacts],
  );

  return (
    <Modal
      isOpen
      allowMobileDrawer
      onClose={onClose}
      title={t(title)}
      styles={{ modalContainer: classes.contactsDetailsModal }}
    >
      <div className={classes.contactsDetails}>
        {Object.keys(filteredContacts)?.map((contactType, index) => (
          <div key={index} className={classes.contactGrid}>
            <Typography size="14" italic>
              {t(CONTACT_LABEL[contactType])}
            </Typography>
            <div className={classes.contactSection}>
              {filteredContacts[contactType]?.map(contact => {
                const link = getLink(contact);
                return (
                  <LinkButton
                    key={contact?.id}
                    variant="text"
                    isLink
                    external
                    href={link}
                    disableUnderline
                    styles={{ root: classes.contactLink }}
                    trackingData={{
                      ...trackingData,
                      component: COMPONENTS.ARTIST_DIRECT_CONTACT_MODAL,
                      meta: {
                        isBoxOffice,
                        hasPermissionToView: contact?.hasPermissionToView,
                        contactType: contact?.name,
                        contactValue: contact?.value,
                      },
                    }}
                  >
                    <Typography size="14" className={classes.contactValue}>
                      {contact?.value}
                    </Typography>
                    {CONTACT_TYPE.WEBSITE === contact?.contactType?.slug && (
                      <SpriteIcon className={classes.openNewIcon} icon="open_in_new" size={16} />
                    )}
                  </LinkButton>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ContactDetailsModal;
