import React, { Fragment, useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import PropTypes from 'prop-types';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SectionBlock from 'components/Globals/SectionBlock';
import Typography from 'components/uiLibrary/Typography';
import EntityName from 'components/Globals/EntityName';
import EmptySectionBlock from 'components/Globals/EmptySectionBlock';

import queries from 'containers/Artist/queries';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import {
  TP,
  SORT_OPTION_TYPES,
  REPERTOIRE_SECTION_TYPES,
  REPERTOIRE_STATUSES,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  POPUP_SOURCES,
  ARTIST_CUSTOMIZE,
  PREMIUM_NUDGE_VIEW_TYPES,
  PREMIUM_NUDGE_POSITION,
  VALID_QUERY_PARAMS,
} from 'constants/index';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import useProfileCustomizationToggle from 'utils/hooks/useProfileCustomizationToggle';

import { REPERTOIRE_SECTIONS } from 'constants/consts';
import { artistEditTabValues } from 'containers/Artist/constants';
import { createHorizontalSections } from 'utils/globals/app';
import classes from './RepertoireListing.module.scss';

const futureRepertoireViewTrackingData = {
  section: SECTIONS.REPERTOIRE_LIST,
  component: COMPONENTS.FUTURE,
};

const pastRepertoireViewTrackingData = {
  section: SECTIONS.REPERTOIRE_LIST,
  component: COMPONENTS.PAST,
};

const ComposerName = ({ composers = [], trackingData }) =>
  composers.map((composer, index) => (
    <Fragment key={index}>
      <EntityName
        key={composer?.id}
        entityType={ENTITY_TYPE.ARTIST}
        entity={{ id: composer?.profile?.id, name: composer?.profile?.shortName }}
        trackingData={trackingData}
        isRaw
      />
      {index < composers.length - 1 && ', '}
    </Fragment>
  ));

const RepertoireTable = ({ repertoireData, isFutureRepertoire, isDesktop, isOverview, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  if (isOverview || !isDesktop) {
    return (
      <div>
        {Object.keys(repertoireData)?.map((composerName, composerIndex) => (
          <div key={composerIndex} className={classes.overviewComposer}>
            <Typography size="14" color="secondary" italic>
              <ComposerName composers={repertoireData[composerName]?.composer} trackingData={trackingData} />
            </Typography>
            {repertoireData[composerName]?.data?.map(work => (
              <div key={work.id} className={classes.overviewWork}>
                <Typography size="14" weight="bold">
                  <EntityName
                    entityType={ENTITY_TYPE.WORK}
                    entity={{ id: work.id, name: work.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                </Typography>
                <div className={classes.overviewRoleInfo}>
                  {work?.roles?.length > 0 ? (
                    <div className={classes.overviewRoleSection}>
                      {work.roles.map((roleName, roleIndex) => (
                        <Typography key={roleIndex} size="14" color="secondary" className={classes.overviewRoleName}>
                          <SpriteIcon icon="role_mask" size="14" />
                          <Typography color="secondary" strikethrough={work?.status === REPERTOIRE_STATUSES.RETIRE}>
                            {roleName} {work?.status === REPERTOIRE_STATUSES.RETIRE && `(${t('RETIRED')})`}
                          </Typography>
                        </Typography>
                      ))}
                    </div>
                  ) : (
                    <Typography color="secondary" className={classes.overviewRoleName}>
                      <SpriteIcon icon="role_mask" size="14" />
                      {work?.professionName}
                    </Typography>
                  )}
                  {isFutureRepertoire && (
                    <Typography size="12" className={classes.status}>
                      {work?.status === REPERTOIRE_STATUSES.UNPREPARED
                        ? t(`${TP}.FN_ROLE_UNPREPARED`)
                        : t(`${TP}.FN_ROLE_PREPARED`)}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography size="12">
          {t(`${TP}.m_COMPOSER`)} & {t(`${TP}.m_MUSICALWORK`)}
        </Typography>
        <Typography size="12">{t(`${TP}.FN_ROLE_TITLE`)}</Typography>
        {isFutureRepertoire && <Typography size="12">{t(`${TP}.m_STATUS`)}</Typography>}
      </div>
      <div>
        {Object.keys(repertoireData)?.map((composerName, composerIndex) => (
          <div key={composerIndex} className={classes.composer}>
            <Typography size="14" color="secondary" italic>
              <ComposerName composers={repertoireData[composerName]?.composer} trackingData={trackingData} />
            </Typography>
            {repertoireData[composerName]?.data?.map(work => (
              <div key={work.id} className={classes.work}>
                <Typography size="14" weight="bold" className={classes.workName}>
                  <EntityName
                    entityType={ENTITY_TYPE.WORK}
                    entity={{ id: work.id, name: work.name }}
                    trackingData={trackingData}
                    isRaw
                  />
                </Typography>
                <div className={classes.role}>
                  {work?.roles?.length > 0 ? (
                    work.roles.map((roleName, roleIndex) => (
                      <Typography color="secondary" className={classes.roleName} key={roleIndex}>
                        <SpriteIcon icon="role_mask" size="14" />
                        <Typography color="secondary" strikethrough={work?.status === REPERTOIRE_STATUSES.RETIRE}>
                          {roleName} {work?.status === REPERTOIRE_STATUSES.RETIRE && `(${t('RETIRED')})`}
                        </Typography>
                      </Typography>
                    ))
                  ) : (
                    <Typography color="secondary" className={classes.roleName}>
                      <SpriteIcon icon="role_mask" size="14" />
                      {work?.professionName}
                    </Typography>
                  )}
                </div>
                {isFutureRepertoire && (
                  <Typography size="12" className={classes.status}>
                    {work?.status === REPERTOIRE_STATUSES.UNPREPARED
                      ? t(`${TP}.FN_ROLE_UNPREPARED`)
                      : t(`${TP}.FN_ROLE_PREPARED`)}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const RepertoireListing = ({ entity, entityType, type, isOverview = false, sectionProps = {}, isEditMode }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { permissions, filters, navigate } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const { castingToolPermissions, isAdmin } = permissions;
  const hasCastingToolAccess = isAdmin || castingToolPermissions?.hasAccess;
  const proArtist = entity?.stats?.isPro;
  const isFutureRepertoire = type === REPERTOIRE_SECTION_TYPES.FUTURE;
  const enableShowHideToggle = !!isOverview && !!isEditMode;
  const trackingData = isFutureRepertoire ? futureRepertoireViewTrackingData : pastRepertoireViewTrackingData;

  const { data: repertoireData, isLoading } = useQuery(
    queries.getRepertoireDetails({
      id: entity?.id,
      ...(isEditMode && { asEdit: true }),
      filters: {
        sort: SORT_OPTION_TYPES.COMPOSERS_ASC?.value,
        ...(filters || {}),
      },
      queryConfig: {
        enable:
          isEditMode || type === REPERTOIRE_SECTION_TYPES.FUTURE
            ? entity?.stats?.repertoire?.future?.exists
            : entity?.stats?.repertoire?.past?.exists,
      },
    }),
  );

  const { data: profileCustList, onToggle: handleShowHide } = useProfileCustomizationToggle({
    entity,
    key: isFutureRepertoire ? ARTIST_CUSTOMIZE.FUTURE_REPERTOIRE : ARTIST_CUSTOMIZE.REPERTOIRE,
    enabled: !!enableShowHideToggle,
  });

  const linkProps = useMemo(
    () => ({
      ...navigate.getLinkProps({
        entityType,
        entity,
        path: ENTITY_MAIN_TABS.REPERTOIRE,
        edit: isEditMode,
      }),
      isLink: true,
    }),
    [entityType, entity, navigate, isEditMode],
  );

  const sectionBlockProps = useMemo(
    () => ({
      ...sectionProps,
      title: t(isFutureRepertoire ? `${TP}.FN_FUTURE_REPERTOIRE` : `${TP}.m_LISTREPERTOIRE`),
      ...(isEditMode && {
        linkProps: linkProps.getSubPath({
          queryParams: { tab: artistEditTabValues.repertoire },
          scrollTo: isFutureRepertoire ? REPERTOIRE_SECTIONS.FUTURE_REPERTOIRE : REPERTOIRE_SECTIONS.REPERTOIRE,
        }),
        editMode: { ...sectionProps?.editMode, trackingData },
        ...(isOverview && {
          premiumSection: {
            enabled: !entity?.stats?.isPro,
            nudgeSourceType: isFutureRepertoire ? POPUP_SOURCES.FUTURE_REPERTOIRE : POPUP_SOURCES.REPERTOIRE,
            description: !isFutureRepertoire ? t(`${TP}.FN_REPERTOIRE_PLAN_NUDGE_DESCRIPTION`) : '',
            trackingData,
          },
        }),
        ...(enableShowHideToggle && {
          showHideProps: {
            enabled: true,
            onToggle: handleShowHide,
            value:
              profileCustList?.[isFutureRepertoire ? ARTIST_CUSTOMIZE.FUTURE_REPERTOIRE : ARTIST_CUSTOMIZE.REPERTOIRE]
                ?.value,
            specialPermission: { permission: entity?.stats?.isPro },
            popupSource: isFutureRepertoire ? POPUP_SOURCES.FUTURE_REPERTOIRE : POPUP_SOURCES.REPERTOIRE,
            trackingData,
          },
        }),
      }),
    }),
    [
      isFutureRepertoire,
      sectionProps,
      isEditMode,
      trackingData,
      entity?.stats?.isPro,
      t,
      enableShowHideToggle,
      handleShowHide,
      profileCustList,
      isOverview,
    ],
  );

  if (isLoading || (!isEditMode && isFutureRepertoire && !proArtist && !hasCastingToolAccess)) {
    return null;
  }

  if (
    isEditMode &&
    ((isFutureRepertoire && !repertoireData?.futureRepertoireCount) ||
      (!isFutureRepertoire && !repertoireData?.scheduleRepertoireCount))
  ) {
    return (
      <EmptySectionBlock
        sectionProps={{
          ...sectionBlockProps,
          premiumSection: {
            ...sectionBlockProps?.premiumSection,
            type: PREMIUM_NUDGE_VIEW_TYPES.CONCISE,
            placement: PREMIUM_NUDGE_POSITION.TOP,
          },
          emptySections: createHorizontalSections([
            {
              title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, {
                section: t(isFutureRepertoire ? `CL_ADD_FUTURE_REPERTOIRE` : `${TP}.m_LISTREPERTOIRE`)?.toLowerCase(),
              }),
              linkProps: linkProps?.getSubPath({
                queryParams: {
                  tab: artistEditTabValues.repertoire,
                  [VALID_QUERY_PARAMS.OPEN_MODAL]: isFutureRepertoire
                    ? REPERTOIRE_SECTIONS.FUTURE_REPERTOIRE
                    : REPERTOIRE_SECTIONS.REPERTOIRE,
                },
                scrollTo: isFutureRepertoire ? REPERTOIRE_SECTIONS.FUTURE_REPERTOIRE : REPERTOIRE_SECTIONS.REPERTOIRE,
              }),
              trackingData,
            },
          ]),
          description: t(
            isFutureRepertoire ? `${TP}.FN_FUTURE_REPERTOIRE_DESCRIPTION` : `${TP}.FN_REPERTOIRE_DESCRIPTION`,
          ),
        }}
      />
    );
  }

  return (
    <SectionBlock
      count={isFutureRepertoire ? repertoireData?.futureRepertoireCount : repertoireData?.scheduleRepertoireCount}
      {...(isOverview && { linkProps })}
      {...sectionBlockProps}
      {...(isEditMode && {
        premiumSection: {
          ...sectionBlockProps?.premiumSection,
          type: PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
          placement: PREMIUM_NUDGE_POSITION.MIDDLE,
        },
      })}
      seeAllTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.SEE_ALL_CTA }}
    >
      <RepertoireTable
        repertoireData={isFutureRepertoire ? repertoireData?.futureRepertoire : repertoireData?.scheduleRepertoire}
        isFutureRepertoire={isFutureRepertoire}
        isOverview={isOverview}
        isDesktop={isDesktop}
        trackingData={trackingData}
      />
    </SectionBlock>
  );
};

RepertoireListing.propTypes = {
  type: PropTypes.oneOf([REPERTOIRE_SECTION_TYPES.FUTURE, REPERTOIRE_SECTION_TYPES.PAST]),
};

export default RepertoireListing;
