import React from 'react';

import PreviewCard from 'components/Globals/PreviewCard';

import classes from './FeaturedEntities.module.scss';

const FeaturedEntities = ({ data, entityType, trackingData }) => (
  <div className={classes.root}>
    {data?.map(({ [entityType]: entity }) => (
      <PreviewCard
        key={entity?.id}
        entity={entity}
        entityType={entityType}
        trackingData={{ ...trackingData, entityId: entity?.id, entityName: entity?.name, entityType }}
      />
    ))}
  </div>
);

export default FeaturedEntities;
