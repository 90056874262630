import React, { useState, useMemo } from 'react';
import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES } from 'components/Globals/Analytics/constants';

import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import Modal from 'components/uiLibrary/Modal';
import Loader from 'components/uiLibrary/Loader';

import { TP, BASE_PAGE_ROUTES, PRIVACY_ATTRIBUTE_IDS, PROFILE_TYPE_IDS } from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import { TRACK_EVENTS, CONTACT_NO_ACCESS_IDS } from 'utils/tracking';
import { useTranslation } from 'src/i18n';

import classes from './ContactNoAccessModal.module.scss';

const PRIVACY_ACCESS_TITLE = {
  [PRIVACY_ATTRIBUTE_IDS.AGENCY]: `${TP}.M_AGENCY`,
  [PRIVACY_ATTRIBUTE_IDS.AOS]: `${TP}.FN_ORGANIZATIONS`,
  [PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL]: `${TP}.FN_CASTINGTOOL`,
};

const ContactNoAccessModal = ({ isMobile, selectedContact, onClose }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const { navigate } = usePageContext();
  const [isLoading, setIsLoading] = useState(false);

  const { privacy1, privacy2 } = useMemo(() => {
    const privacyAttributes = Array.from(
      new Map(selectedContact?.privacyAttributes?.map(item => [item.id, item]))?.values(),
    );
    const isOnlyObUsersAllowed = privacyAttributes?.[0]?.id === PRIVACY_ATTRIBUTE_IDS.FREE_AND_PRO;

    const primaryPrivacyTitle = isOnlyObUsersAllowed
      ? t(PRIVACY_ACCESS_TITLE[PRIVACY_ATTRIBUTE_IDS.AGENCY])
      : t(PRIVACY_ACCESS_TITLE[privacyAttributes?.[0]?.id]);

    let additionalPrivacyTitle = '';
    if (isOnlyObUsersAllowed) {
      additionalPrivacyTitle = `, ${t(PRIVACY_ACCESS_TITLE[PRIVACY_ATTRIBUTE_IDS.AOS])}`;
    } else if (privacyAttributes?.[1]?.id) {
      additionalPrivacyTitle = `, ${t(PRIVACY_ACCESS_TITLE[privacyAttributes?.[1]?.id])}`;
    }

    return { privacy1: primaryPrivacyTitle, privacy2: additionalPrivacyTitle };
  }, [selectedContact?.privacyAttributes, t]);

  if (!selectedContact) {
    return null;
  }

  const handleCreateRegistration = registerType => {
    setIsLoading(true);

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: CONTACT_NO_ACCESS_IDS.REGISTER,
        meta: { register_type: registerType },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    navigate.to(
      navigate.getLinkProps({
        baseRoute: BASE_PAGE_ROUTES.REGISTER,
        queryParams: { as: registerType },
        onlyLinkProps: true,
      }),
    );
  };

  return (
    <Modal
      isOpen
      title={t('CONTACT_CASTING_TITLE')}
      onClose={onClose}
      styles={{
        modalContainer: classes.noAccessModal__container,
      }}
      isDrawer={isMobile}
    >
      <div>
        <div className={classes.noAccessModal__content}>
          <Typography>
            {t('CONTACT_CASTING_DESC', {
              privacy_1: privacy1,
              privacy_2: privacy2,
            })}
          </Typography>
        </div>
        <div className={classes.noAccessModal__footer}>
          <LinkButton
            styles={{ root: classes.noAccessModal__primaryCTA }}
            onClick={() => handleCreateRegistration(PROFILE_TYPE_IDS.AOS)}
          >
            {t('ARE_YOU_ORGANISATION')}
          </LinkButton>
          <LinkButton
            styles={{ root: classes.noAccessModal__secondaryCTA }}
            onClick={() => handleCreateRegistration(PROFILE_TYPE_IDS.AGENT)}
          >
            {t('ARE_YOU_AGENCY')}
          </LinkButton>
        </div>
        {isLoading && (
          <div className={classes.loader}>
            <Loader isLoading={isLoading} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ContactNoAccessModal;
