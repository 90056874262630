import React, { useMemo } from 'react';
import classnames from 'classnames';

import Modal from 'components/uiLibrary/Modal';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import EntityName from 'components/Globals/EntityName';

import { AGREEMENTS_TYPES } from 'containers/Managers/constants';

import { TP, ENTITY_TYPE, CONTACT_LABEL, CONTACT_TYPE } from 'constants/index';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import { addMailTolIfNeed, addProtocolIfNeed, isValidHttpUrl } from 'utils/common';
import { useBuildContacts } from 'utils/contacts';
import { SECTIONS } from 'components/Globals/Analytics/constants';

import classes from './AgencyDetailsModal.module.scss';

const validContactTypes = [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE];

const trackingData = {
  section: SECTIONS.AGENCY_DETAILS_MODAL,
};

const getLink = contactItem => {
  const { value, href } = contactItem || {};
  return contactItem?.contactType?.id === 1
    ? addMailTolIfNeed(href) || addMailTolIfNeed(value)
    : addProtocolIfNeed(href) || isValidHttpUrl(value);
};

const AgreementTerritory = ({ title, agreements }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  if (!agreements?.length) return null;

  return (
    agreements?.length > 0 && (
      <div className={classnames(classes.agencyGrid, classes.agreementGrid)}>
        <Typography size="14" italic>
          {t(title)}
        </Typography>
        <div className={classes.agreementDetails}>
          {agreements?.map(agreement => (
            <Typography key={agreement?.id}>
              <Typography size="14" color="secondary">
                {agreement?.isExcluded ? t(`${TP}.REPRESENTATION_EXCLUDE`) : t(`${TP}.REPRESENTATION_INCLUDE`)}
              </Typography>
              :{' '}
              <Typography className={classes.agreementName} size="12">
                {agreement?.name}
              </Typography>
            </Typography>
          ))}
        </div>
      </div>
    )
  );
};

const AgencyContacts = ({ contacts }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const filteredContacts = useMemo(
    () =>
      Object.values(contacts)?.reduce((result, contactArray) => {
        const contactTypeSlug = contactArray?.[0]?.contactType?.slug;

        if (validContactTypes.includes(contactTypeSlug)) {
          return { ...result, [contactTypeSlug]: contactArray };
        }

        return result;
      }, {}),
    [contacts],
  );

  if (!Object.keys(contacts)?.length) {
    return null;
  }

  return Object.keys(filteredContacts)?.map((contactType, index) => (
    <div key={index} className={classes.agencyGrid}>
      <Typography size="14" italic>
        {t(CONTACT_LABEL[contactType])}
      </Typography>
      <div className={classes.agencyContacts}>
        {filteredContacts?.[contactType]?.map(contact => {
          const link = getLink(contact);
          return (
            <LinkButton
              key={contact?.id}
              variant="text"
              isLink
              external
              href={link}
              disableUnderline
              styles={{ root: classes.contactLink }}
              trackingData={{
                ...trackingData,
                meta: {
                  contactType: contact?.name,
                  contactValue: contact?.value,
                },
              }}
            >
              <Typography size="14" className={classes.contactValue}>
                {contact?.value}
              </Typography>
              {[CONTACT_TYPE.WEBSITE, CONTACT_TYPE.EMAIL].includes(contact?.contactType?.slug) && (
                <SpriteIcon className={classes.openNewIcon} icon="open_in_new" size={16} />
              )}
            </LinkButton>
          );
        })}
      </div>
    </div>
  ));
};

const AgentItem = ({ label, value, isName, href }) => {
  const link = getLink({ value, href });

  if (!value) return null;

  return (
    <div className={classes.agencyGrid}>
      <Typography size="14" italic>
        {label}
      </Typography>
      {isName ? (
        <Typography size="14" weight={isName ? 'bold' : 'regular'}>
          {value}
        </Typography>
      ) : (
        <LinkButton variant="text" isLink external href={link} disableUnderline styles={{ root: classes.agencyLink }}>
          <Typography size="14" weight={isName ? 'bold' : 'regular'} className={classes.contactValue}>
            {value}
          </Typography>
        </LinkButton>
      )}
    </div>
  );
};

const AgentInfo = ({ agent }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const AGENT_DETAILS = [
    { key: 'agentName', label: 'AGENT_NAME', isName: true },
    { key: 'agentPhoneNumber', label: 'AGENT_PHONE_NO' },
    { key: 'agentEmail', label: 'AGENT_EMAIL', hrefPrefix: 'mailto:' },
  ];

  return (
    <div className={classes.agencyDetailsSection}>
      <Typography size="14" weight="medium" className={classes.agencySectionTitle}>
        {t(`${TP}.FN_REPRESENTATION_AGREEMENT_DETAILS`)}
      </Typography>
      {AGENT_DETAILS.map(({ key, label, isName, hrefPrefix }) => (
        <AgentItem
          key={key}
          label={t(label)}
          value={agent?.[key]}
          isName={isName}
          href={hrefPrefix ? `${hrefPrefix}${agent?.[key]}` : undefined}
        />
      ))}
    </div>
  );
};

const AgencyDetailsModal = ({ agency, onClose }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const agencyInfo = { ...agency?.agency };
  const agreementDetails = useMemo(
    () =>
      agency?.regions?.reduce(
        (acc, region) => {
          if (region?.agreementType === AGREEMENTS_TYPES.OPERA) {
            if (region?.exclusive) {
              acc.operaExclusive.push(region);
            } else {
              acc.operaNonExclusive.push(region);
            }
          }

          if (region?.agreementType === AGREEMENTS_TYPES.CONCERT) {
            if (region?.exclusive) {
              acc.concertExclusive.push(region);
            } else {
              acc.concertNonExclusive.push(region);
            }
          }

          return acc;
        },
        { operaExclusive: [], operaNonExclusive: [], concertExclusive: [], concertNonExclusive: [] },
      ),
    [agency?.regions],
  );
  const { generalContacts } = useBuildContacts({ contacts: agencyInfo?.contacts });

  return (
    <Modal isOpen allowMobileDrawer onClose={onClose} title={t('AGENCY_DETAILS_MODAL_TITLE')}>
      <div className={classes.agencyDetails}>
        <div className={classes.agencyDetailsSection}>
          <Typography size="14" weight="medium" className={classes.agencySectionTitle}>
            {t('AGENCY_INFORMATION')}
          </Typography>
          <div className={classes.agencyGrid}>
            <Typography size="14" italic>
              {t(`${TP}.FN_NAME`)}
            </Typography>
            <div className={classes.agencyName}>
              <LinkButton
                key={agency?.id}
                variant="text"
                isLink
                target="_blank"
                disableUnderline
                styles={{ root: classes.agencyLink }}
                trackingData={{
                  ...trackingData,
                  entityId: agencyInfo?.id,
                  entityName: agencyInfo?.name,
                  entityType: ENTITY_TYPE.MANAGER,
                }}
                {...navigate.getLinkProps({
                  entity: agencyInfo,
                  entityType: ENTITY_TYPE.MANAGER,
                })}
              >
                <Typography size="16" weight="bold">
                  <EntityName entity={agencyInfo} entityType={ENTITY_TYPE.MANAGER} trackingData={trackingData} isRaw />
                  <SpriteIcon className={classes.openNewIcon} icon="open_in_new" size={16} />
                </Typography>
              </LinkButton>
              {agency?.isGM && (
                <Typography className={classes.agreement} color="secondary" size="12">
                  {`(${t(`${TP}.FN_GENERAL_MANAGEMENT`)})`}
                </Typography>
              )}
              {!agency?.acknowledged && (
                <Typography size="12" className={classes.notApproved} italic>
                  <SpriteIcon size={13} icon="report_error" /> {t('AGENCY_NOT_CONFIRMED')}
                </Typography>
              )}
            </div>
          </div>
          <AgencyContacts contacts={generalContacts} />
        </div>
        {agency?.contactPerson?.id && <AgentInfo agent={agency?.contactPerson} />}
        {(agency?.agencyConnectionProfessions?.length > 0 || agency?.regions?.length > 0) && (
          <div className={classes.agencyDetailsSection}>
            <Typography size="14" weight="medium" className={classes.agencySectionTitle}>
              {t('AGENCY_REPRESENTATIVE_DETAILS')}
            </Typography>
            {agency?.agencyConnectionProfessions?.length > 0 && (
              <div className={classes.agencyGrid}>
                <Typography size="14" italic>
                  {t(`${TP}.FN_PROFESSION`)}/ {t(`${TP}.FN_INDUSTRY_PRO_INSTRUMENTALISTS_LABEL`)}
                </Typography>
                <div>
                  {agency?.agencyConnectionProfessions?.map((profession, index) => (
                    <Typography weight="bold" size="14" key={profession?.id}>
                      {index > 0 && ', '}
                      {profession?.profession?.name}
                    </Typography>
                  ))}
                </div>
              </div>
            )}
            {agency?.regions?.length > 0 && (
              <>
                <AgreementTerritory
                  title={`${TP}.m_EDIT_ROSTER_LIST_REPRESENTATION_DETAILS_TITLE_1`}
                  agreements={agreementDetails?.operaExclusive}
                />
                <AgreementTerritory
                  title={`${TP}.m_EDIT_ROSTER_LIST_REPRESENTATION_DETAILS_TITLE_2`}
                  agreements={agreementDetails?.operaNonExclusive}
                />
                <AgreementTerritory
                  title={`${TP}.m_EDIT_ROSTER_LIST_REPRESENTATION_DETAILS_TITLE_3`}
                  agreements={agreementDetails?.concertExclusive}
                />
                <AgreementTerritory
                  title={`${TP}.m_EDIT_ROSTER_LIST_REPRESENTATION_DETAILS_TITLE_4`}
                  agreements={agreementDetails?.concertNonExclusive}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AgencyDetailsModal;
