import React, { useMemo } from 'react';

import Content from 'components/Globals/Layout/StubPage/Content';
import EntityUpcomingPerformances from 'components/Productions/Display/EntityUpcomingPerformances';
import EntityContactBlock from 'components/Globals/EntityContactBlock';
import { Seasons } from 'components/Productions/Display/Seasons';
import VideoListing, { VIDEO_SECTION_TYPES } from 'components/Media/Display/VideoListing';
import EntityRentalsList from 'components/Productions/Display/EntityRentalsList';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import AboutOrg from '../AboutOrg';
import AssociatedOrgs from '../AssociatedOrgs';
import Venues from '../Venues';

const Home = ({ entityType, entity }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  const sections = useMemo(() => {
    const stats = entity?.stats || {};

    const components = [
      {
        component: AboutOrg,
        props: {
          showMinDescription: true,
          enableSeeAll: false,
        },
        placement: {
          desktop: {
            column: 3,
            order: 1,
          },
          tablet: {
            hide: true,
          },
          mobile: {
            hide: true,
          },
        },
        showSection: stats?.about?.exists,
      },
      {
        component: AssociatedOrgs,
        props: {
          enableSeeAll: false,
          isOverview: true,
        },
        placement: {
          desktop: {
            column: 3,
            order: 2,
          },
          tablet: {
            column: 1,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 3,
          },
        },
        showSection: stats?.associatedOrgs?.exists,
      },
      {
        component: EntityUpcomingPerformances,
        props: {
          enableSeeAll: true,
          infinite: false,
        },
        placement: {
          desktop: {
            column: 2,
            order: 1,
          },
          tablet: {
            column: 2,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 1,
          },
        },
        showSection: stats?.productions?.future?.exists,
      },
      {
        component: Venues,
        props: {
          enableSeeAll: false,
          isOverview: true,
        },
        placement: {
          desktop: {
            column: 3,
            order: 3,
          },
          tablet: {
            column: 1,
            order: 4,
          },
          mobile: {
            column: 1,
            order: 4,
          },
        },
        showSection: stats?.venues?.exists,
      },
      {
        component: EntityContactBlock,
        props: {
          enableSeeAll: false,
          isOverview: true,
        },
        placement: {
          desktop: {
            column: 3,
            order: 5,
          },
          tablet: {
            column: 1,
            order: 6,
          },
          mobile: { column: 1, order: 6 },
        },
        showSection: stats?.contacts?.exists,
      },
      {
        component: VideoListing,
        props: {
          type: VIDEO_SECTION_TYPES.PRODUCTION,
          limit: 3,
          infinite: false,
          title: t(`${TP}.FN_FEATURED_PERFORMANCE_VIDEOS`),
          trackingData: {
            section: SECTIONS.FEATURED_VIDEOS,
            component: COMPONENTS.MEDIA_CARD,
          },
        },
        showSection: stats?.media?.videos?.production?.exists,
        placement: {
          desktop: {
            column: 2,
            order: 3,
          },
          tablet: {
            column: 2,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 4,
          },
        },
      },
      {
        component: Seasons,
        props: {
          isOverview: true,
        },
        placement: {
          desktop: {
            column: 1,
            order: 1,
          },
          tablet: {
            column: 1,
            order: 1,
          },
          mobile: {
            column: 1,
            order: 2,
          },
        },
        showSection: stats?.productions?.past?.exists,
      },
      {
        component: EntityRentalsList,
        props: {
          limit: 3,
          enableSeeAll: true,
          disabled: true,
          isOverview: true,
          title: t('PRODUCTION_RENTALS'),
        },
        placement: {
          desktop: {
            column: 3,
            order: 4,
          },
          tablet: {
            column: 2,
            order: 3,
          },
          mobile: {
            column: 1,
            order: 5,
          },
        },
        showSection: stats?.rentals?.exists,
      },
    ];

    return components;
  }, [entity, t]);

  return <Content entity={entity} entityType={entityType} sections={sections} equalColumnWidth />;
};

export default Home;
