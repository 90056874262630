import React, { useMemo } from 'react';

import SectionBlock from 'components/Globals/SectionBlock';
import ProductionListing, { VIEW_TYPES } from 'components/Productions/Common/ProductionListing';
import { useSeasonFilters } from 'components/Productions/Display/Seasons';

import usePageContext from 'utils/hooks/usePageContext';
import { getValidFilters } from 'utils/globals/getRouteContext';
import { ENTITY_MAIN_TABS, TP } from 'constants/index';
import { FILTER_TYPES } from 'constants/filters';

import { useTranslation } from 'src/i18n';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import classes from './EntityUpcomingPerformances.module.scss';

const upcomingPerformanceTrackingDataSection = {
  section: SECTIONS.UPCOMING_PERFORMANCES,
};

const seeAllUpcomingPerformanceTrackingData = {
  ...upcomingPerformanceTrackingDataSection,
  component: COMPONENTS.SEE_ALL_CTA,
};
const upcomingPerformanceTrackingDataSlug = {
  ...upcomingPerformanceTrackingDataSection,
  component: COMPONENTS.PRODUCTION_SLUG,
};

const EntityUpcomingPerformances = ({
  entity,
  entityType,
  infinite = false,
  enableSeeAll = true,
  enableViewAllButton = true,
  sectionProps = {},
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();

  const applicableFilters = useMemo(() => {
    const validFilters = getValidFilters({ entityType, mainPath: ENTITY_MAIN_TABS.PERFORMANCES });

    return validFilters?.filter(type => type !== FILTER_TYPES.SEASON);
  }, [entityType]);

  const baseFilters = useSeasonFilters({ upcoming: true });

  const linkProps = navigate.getLinkProps({ entity, entityType, path: ENTITY_MAIN_TABS.PERFORMANCES });

  return (
    <SectionBlock
      title={t(`${TP}.FN_UPCOMING_PERFORMANCES`)}
      description={t('UPCOMING_PERFORMANCES_DESCRIPTION')}
      linkProps={{
        ...linkProps,
        title: t('VIEW_ALL_CTA', { title: t(`${TP}.FN_UPCOMING_PERFORMANCES`) }),
      }}
      count={entity?.stats?.performances?.future?.total}
      seeAllTrackingData={seeAllUpcomingPerformanceTrackingData}
      enableSeeAll={enableSeeAll}
      enableViewAllButton={enableViewAllButton}
      filterProps={{
        types: applicableFilters,
        commonFilterParams: baseFilters,
        clearExcludedTypes: [FILTER_TYPES.SEASON],
        mainPath: ENTITY_MAIN_TABS.PERFORMANCES,
        upcoming: true,
      }}
      {...sectionProps}
    >
      <div className={classes.root}>
        <ProductionListing
          entityType={entityType}
          entity={entity}
          infinite={infinite}
          trackingData={upcomingPerformanceTrackingDataSlug}
          viewMode={VIEW_TYPES.PERFORMANCE}
          filters={baseFilters}
          upcoming
          limit={15}
        />
      </div>
    </SectionBlock>
  );
};

export default EntityUpcomingPerformances;
