import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import SectionBlock from 'components/Globals/SectionBlock';
import ParseHtml from 'components/ParseHtml';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import SelectDropdown from 'components/uiLibrary/Inputs/SelectDropdown';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import EntityContactBlock from 'components/Globals/EntityContactBlock';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useBioDescriptions from 'utils/hooks/useBioDescriptions';
import usePageContext from 'utils/hooks/usePageContext';
import { TP, ENTITY_MAIN_TABS } from 'constants/index';

import AssociatedOrgs from '../AssociatedOrgs';
import classes from './AboutOrg.module.scss';

const AboutInfo = ({ bioContent, showMinDescription, linkProps, trackingData, language }) => {
  const Wrapper = showMinDescription ? LinkButton : Fragment;
  const router = useRouter();
  const handleLinkProps = () => {
    router.push(linkProps?.as);
  };

  return (
    <Wrapper
      {...(showMinDescription && {
        onClick: handleLinkProps,
        variant: 'text',
        styles: { root: classes.about },
        trackingData: { ...trackingData, component: COMPONENTS.CONTENT },
      })}
    >
      <ParseHtml
        className={classnames(classes.bioText, { [classes.clampedContent]: showMinDescription })}
        lang={language}
      >
        {bioContent}
      </ParseHtml>
    </Wrapper>
  );
};

const OrgLogo = ({ profilePictureUrl, name, width = 260, height = 372 }) => (
  <Image src={profilePictureUrl} transformations={TRANSFORMATIONS.ORG_LOGO} alt={name} width={width} height={height} />
);

// TODO: Complete revamped need to be done on this component as is has same features as entityAbout
const AboutOrg = ({ entity, showMinDescription = false, entityType, showContacts = false, enableSeeAll = true }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isMobile } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const { logo, name } = entity || {};

  const { onChangeLang, chosenLang, bioContent, langOptions, isLoading, language } = useBioDescriptions({ entity });
  const profilePictureUrl = logo?.medium;

  const linkProps = navigate.getLinkProps({
    entity,
    entityType,
    suffix: ENTITY_MAIN_TABS.ABOUT,
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" height={300} />;
  }

  if (isMobile && showMinDescription) {
    const about = `<b>${t(`${TP}.ABOUT`)}</b> ${bioContent}`;
    return (
      <div className={classes.mobileSection}>
        <div className={classes.mobileSection__wrapper}>
          <div className={classes.mobileSection__wrapper_logo}>
            <OrgLogo profilePictureUrl={profilePictureUrl} name={name} width={85} height={120} />
          </div>
          <div className={classes.mobileSection__wrapper_content}>
            <AboutInfo
              showMinDescription={showMinDescription}
              bioContent={about}
              linkProps={linkProps}
              language={language}
            />
            {showContacts && <EntityContactBlock entity={entity} entityType={entityType} />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <SectionBlock
      title={bioContent && t(`${TP}.ABOUT`)}
      withTitle={!!bioContent}
      seeAllTrackingData={{
        section: SECTIONS.ABOUT,
        component: COMPONENTS.SEE_ALL_CTA,
      }}
      {...(bioContent &&
        !showMinDescription && {
          titleChildren: (
            <>
              {langOptions?.length > 1 ? (
                <div className={classes.langDropdownLarge}>
                  <Typography size={12}>{t(`${TP}.FN_AVAILABLE_IN`)}</Typography>
                  <SelectDropdown
                    styles={{
                      select: classes.select,
                      formControl: classes.formControl,
                    }}
                    options={langOptions}
                    value={chosenLang?.value}
                    onChange={onChangeLang}
                    hideBorder
                  />
                </div>
              ) : (
                <div className={classes.singleLanguage}>
                  <Typography size={12}>{t(`${TP}.FN_AVAILABLE_IN`)}</Typography>
                  <Typography size={12} className={classes.singleLanguage__name}>
                    {chosenLang?.language?.name}
                  </Typography>
                </div>
              )}
            </>
          ),
        })}
      {...(showMinDescription && {
        linkProps,
      })}
      enableSeeAll={enableSeeAll}
      enableDivider
      className={classes.bioSection}
    >
      {bioContent && (
        <div>
          <div className={classes.bioWrapper}>
            <AboutInfo
              showMinDescription={showMinDescription}
              bioContent={bioContent}
              linkProps={linkProps}
              trackingData={{
                section: SECTIONS.ABOUT,
              }}
              language={language}
            />
            {profilePictureUrl && !showMinDescription && (
              <div className={classes.profileImage}>
                <OrgLogo profilePictureUrl={profilePictureUrl} name={name} />
              </div>
            )}
          </div>

          {showMinDescription && langOptions?.length > 1 && (
            <Typography size={12} color="secondary" italic>
              ({t(`${TP}.FN_AVAILABLE_IN`)}: {langOptions?.map(lang => lang?.language?.name)?.join(', ')})
            </Typography>
          )}
        </div>
      )}
      {!showMinDescription && <AssociatedOrgs entity={entity} entityType={entityType} />}
    </SectionBlock>
  );
};

export default AboutOrg;
