import React from 'react';

import SectionBlock from 'components/Globals/SectionBlock';
import ProductionListing, { VIEW_TYPES } from 'components/Productions/Common/ProductionListing';

import { ENTITY_TYPE, PAGE_SOURCE } from 'constants/index';

import { transformPastDateFilters } from 'utils/globals/filters';

const FeaturedProductions = ({
  title,
  entity,
  entityType,
  filters = {},
  infinite = false,
  limit = 10,
  sectionProps = {},
  trackingData,
}) => (
  <SectionBlock title={title} {...sectionProps}>
    <ProductionListing
      entity={entity}
      entityType={entityType}
      viewMode={VIEW_TYPES.SEASON}
      trackingData={trackingData}
      filters={{
        ...filters,
        has_media: true,
        is_cancelled: false,
        ...transformPastDateFilters(),
        ...(entityType === ENTITY_TYPE.ARTIST && { individual_id: entity?.id, page_source: PAGE_SOURCE.INDIVIDUAL }),
        ...(entityType === ENTITY_TYPE.ORGANIZATION && {
          organization_id: entity?.id,
          page_source: PAGE_SOURCE.ORGANIZATION,
        }),
      }}
      infinite={infinite}
      limit={limit}
      withMedia
    />
  </SectionBlock>
);

export default FeaturedProductions;
