import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import SectionBlock from 'components/Globals/SectionBlock';
import PreviewCard from 'components/Globals/PreviewCard';
import EntityCard from 'components/Globals/EntityCard';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './Venues.module.scss';

const trackingData = {
  section: SECTIONS.VENUES,
  component: COMPONENTS.VENUE_CARD,
};

const Venues = ({ enableSeeAll, sectionProps = {}, isOverview }) => {
  const { entity, entityType, navigate } = usePageContext();
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  return (
    <SectionBlock
      enableSeeAll={enableSeeAll}
      title={t(`${TP}.m_VENUES`)}
      enableDivider={!enableSeeAll}
      count={entity?.venues?.length}
      {...sectionProps}
      {...(isOverview && { linkProps: navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.VENUES }) })}
      className={classes.venuesSection}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <ul
        className={classnames(classes.venues, {
          [classes.venues__landscape]: enableSeeAll && entity?.venues?.length === 1,
        })}
      >
        {entity?.venues?.map(({ venue }) => {
          const completeTrackingData = {
            ...trackingData,
            entityId: venue?.id,
            entityName: venue?.name,
            entityType: ENTITY_TYPE.VENUE,
          };
          if (isOverview) {
            return (
              <li key={venue?.id} className={classes.venue}>
                <EntityCard
                  entityType={ENTITY_TYPE.VENUE}
                  entity={venue}
                  typographyProps={{
                    name: {
                      truncate: true,
                    },
                    subtext: {
                      truncate: true,
                    },
                  }}
                  isLinkShallow={false}
                  trackingData={completeTrackingData}
                />
                <SpriteIcon className={classes.rightIcon} icon="chevron_right" size={18} />
              </li>
            );
          }

          return (
            <li key={venue.id}>
              <PreviewCard
                className={classes.list__item_card}
                entityType={ENTITY_TYPE.VENUE}
                entity={venue}
                trackingData={completeTrackingData}
                typographyProps={{
                  name: {
                    truncate: true,
                  },
                }}
                landscape={enableSeeAll && entity?.venues?.length === 1}
              />
            </li>
          );
        })}
      </ul>
    </SectionBlock>
  );
};

export default Venues;
