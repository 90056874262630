import React, { useCallback, useMemo, useState } from 'react';
import useTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Agencies from 'components/Artist/Display/AgencyAndContact/Agencies';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import ContactDetailsModal from 'components/Globals/EntityContactBlock/ContactDetailsModal';

import {
  TP,
  ENTITY_MAIN_TABS,
  PROFILE_TYPES,
  PRIVACY_ATTRIBUTE_IDS,
  CONTACT_TYPE,
  ENTITY_TYPE,
  CONTACT_LABEL,
  VALID_QUERY_PARAMS,
} from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useBuildContacts } from 'utils/contacts';
import { TRACK_EVENTS, CONTACT_NO_ACCESS_IDS } from 'utils/tracking';

import { useTranslation } from 'src/i18n';

import { artistEditTabValues } from 'containers/Artist/constants';
import { ARTIST_AGENCY_SECTIONS } from 'constants/consts';
import SectionBlock from '../SectionBlock';
import EntitySocialLinks from '../EntitySocialLinks';
import ContactsList from './ContactsList';
import ContactNoAccessModal from './ContactNoAccessModal';

import classes from './EntityContactBlock.module.scss';

const trackingData = {
  section: SECTIONS.REPRESENTATION_AND_CONTACT,
};

const ContactView = ({
  entity,
  contacts,
  boxOfficeContacts,
  showLoginToView,
  entityType,
  onClickHandler,
  setSelectedContact,
  isEditMode,
}) => {
  const { navigate } = usePageContext();
  const getSectionProps = useCallback(
    ({ tab, addSection }) => {
      if (isEditMode) {
        const { getSubPath } = navigate.getLinkProps({
          entity,
          entityType,
          edit: true,
          queryParams: { tab },
        });

        return {
          editMode: {
            enabled: true,
            hasContent: true,
            linkProps: getSubPath({ queryParams: { tab } }),
            ...(addSection && {
              addLinkProps: getSubPath({
                queryParams: { tab, [VALID_QUERY_PARAMS.OPEN_MODAL]: addSection },
              }),
            }),
          },
          enableSeeAll: false,
        };
      }
      return {};
    },
    [entity, entityType, isEditMode, navigate],
  );

  return (
    <div className={classes.contacts}>
      {entityType === ENTITY_TYPE.ARTIST && (
        <Agencies
          entity={entity}
          setSelectedContact={setSelectedContact}
          isEditMode={isEditMode}
          sectionProps={getSectionProps({
            tab: artistEditTabValues.contactInfo,
            addSection: ARTIST_AGENCY_SECTIONS.AGENCY_REPRESENTATION_INFO,
          })}
        />
      )}
      <ContactsList
        entityType={entityType}
        contacts={contacts}
        showLoginToView={showLoginToView}
        onClickHandler={onClickHandler}
        isEditMode={isEditMode}
        sectionProps={getSectionProps({
          tab: artistEditTabValues.contactInfo,
        })}
        trackingData={trackingData}
      />
      <EntitySocialLinks
        entity={entity}
        isEditMode={isEditMode}
        sectionProps={getSectionProps(artistEditTabValues.digitalPresence)}
        trackingData={trackingData}
      />
      {Object.keys(boxOfficeContacts)?.length > 0 && (
        <ContactsList
          entityType={entityType}
          contacts={boxOfficeContacts}
          showLoginToView={showLoginToView}
          onClickHandler={onClickHandler}
          isEditMode={isEditMode}
          isBoxOffice
        />
      )}
    </div>
  );
};

const ContactOverview = ({
  entity,
  entityType,
  contacts,
  onClickHandler,
  showLoginToView,
  isOverview,
  setSelectedContact,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const contactTypes = useMemo(
    () =>
      Object.keys(contacts)?.filter(contactType =>
        [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE].includes(contactType),
      ),
    [contacts],
  );

  if (entityType === ENTITY_TYPE.ORGANIZATION) {
    return contactTypes?.map(contactType => (
      <LinkButton
        variant="text"
        styles={{ root: classes.contactLink }}
        onClick={onClickHandler}
        {...(!showLoginToView && {
          ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.CONTACT, isPro: false }),
          isLink: true,
          scroll: true,
        })}
      >
        <Typography size={14} weight="medium">
          <Typography size={14} weight="medium">
            {t(CONTACT_LABEL[contactType])}
            {showLoginToView && (
              <Typography className={classes.contactSubtitle} size={12}>
                ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
              </Typography>
            )}
          </Typography>
        </Typography>
        <SpriteIcon icon="chevron_right" size={16} className={classes.rightArrow} />
      </LinkButton>
    ));
  }

  return (
    <>
      {Object?.keys(contacts)?.length > 0 && (
        <LinkButton
          variant="text"
          styles={{ root: classes.contactLink }}
          onClick={onClickHandler}
          {...(!showLoginToView && {
            ...navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.CONTACT, isPro: false }),
            isLink: true,
            scroll: true,
          })}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.SEE_ALL_CTA,
            meta: { tab: 'artistContactInfo', showLoginToView },
          }}
        >
          <Typography size={14} weight="medium">
            <Typography size={14} weight="medium">
              {t('ARTIST_CONTACT_INFO')}
              {showLoginToView && (
                <Typography className={classes.contactSubtitle} size={12}>
                  ({t(`${TP}.FN_LOGIN_TO_VIEW`)})
                </Typography>
              )}
            </Typography>
          </Typography>
          <SpriteIcon icon="chevron_right" size={16} className={classes.rightArrow} />
        </LinkButton>
      )}
      <Agencies entity={entity} isOverview={isOverview} setSelectedContact={setSelectedContact} />
    </>
  );
};

const EditModeOverviewContacts = ({ entityType, generalContacts, sectionProps }) => {
  const [isOpenContactModal, setIsOpenContactModal] = useState(null);
  return (
    <>
      <ContactsList
        entityType={entityType}
        contacts={generalContacts}
        onClickHandler={() => setIsOpenContactModal(true)}
        sectionProps={sectionProps}
        isEditMode
        isOverview
      />
      {isOpenContactModal && (
        <ContactDetailsModal
          entityType={entityType}
          contacts={generalContacts}
          onClose={() => setIsOpenContactModal(false)}
        />
      )}
    </>
  );
};

const EntityContactBlock = ({ isOverview, isEditMode, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const [isOpenContactModal, setIsOpenContactModal] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isBoxOfficeContact, setIsBoxOfficeContact] = useState(false);
  const { isDesktop, isMobile } = useDeviceTypeLayouts();
  const { entity, entityType, navigate, permissions } = usePageContext();
  const { setIsLoginDialog } = useDialogs();
  const { isLoggedIn } = permissions;
  const hasCastingToolAccess = permissions?.castingToolPermissions?.hasAccess;
  const activeProfileTypeId = permissions?.activeProfile?.profileType?.id;

  const { generalContacts, boxOfficeContacts } = useBuildContacts({
    contacts: entity?.contacts || [],
    addresses: entity?.addresses || [],
    profileTypeId: activeProfileTypeId,
    hasCastingToolAccess,
  });

  const contactTabLinkProps = useMemo(
    () => navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.CONTACT, isPro: false }),
    [entity, entityType, navigate],
  );
  const showLoginToView = !isLoggedIn && entity?.subscriptionStatus !== PROFILE_TYPES.PRO;

  const onClickHandler = (contact, isBoxOffice) => {
    track.click({
      ...trackingData,
      component: COMPONENTS.ARTIST_DIRECT_CONTACT,
      meta: {
        isBoxOffice,
        hasPermissionToView: contact?.hasPermissionToView,
        showLoginToView,
        contactType: contact?.name,
        contactValue: contact?.value,
      },
    });

    if (showLoginToView) {
      setIsLoginDialog({ isOpen: true });
      return;
    }

    if (contact && !contact?.hasPermissionToView && !isOverview) {
      setSelectedContact({
        ...contact,
        privacyAttributes:
          contact?.privacyAttributes?.length > 1
            ? contact?.privacyAttributes?.filter(privacy => privacy?.id !== PRIVACY_ATTRIBUTE_IDS.CASTING_TOOL)
            : contact?.privacyAttributes,
      });

      track.click(
        {
          name: TRACK_EVENTS.CLICK,
          id: CONTACT_NO_ACCESS_IDS.VIEW,
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
      return;
    }

    setIsBoxOfficeContact(isBoxOffice);

    if (!isOverview) {
      setIsOpenContactModal(true);
    }
  };

  if (isEditMode && isOverview) {
    return (
      <EditModeOverviewContacts entityType={entityType} generalContacts={generalContacts} sectionProps={sectionProps} />
    );
  }

  return (
    <>
      {isOverview ? (
        <SectionBlock
          title={entityType === ENTITY_TYPE.ARTIST ? t(`${TP}.FN_REPRESENTATION_AND_CONTACT`) : t(`${TP}.m_CONTACT`)}
          linkProps={contactTabLinkProps}
          {...sectionProps}
          seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
        >
          <div className={classes.contactSection}>
            <ContactOverview
              entity={entity}
              entityType={entityType}
              onClickHandler={onClickHandler}
              showLoginToView={showLoginToView}
              isOverview={isOverview}
              setSelectedContact={setSelectedContact}
              contacts={generalContacts}
              isDesktop={isDesktop}
            />
            {isDesktop && !isEditMode && generalContacts[CONTACT_TYPE.SNS]?.length > 0 && (
              <EntitySocialLinks inline entity={entity} trackingData={trackingData} />
            )}
          </div>
        </SectionBlock>
      ) : (
        <>
          <ContactView
            entity={entity}
            entityType={entityType}
            contacts={generalContacts}
            boxOfficeContacts={boxOfficeContacts}
            showLoginToView={showLoginToView}
            onClickHandler={onClickHandler}
            setSelectedContact={setSelectedContact}
            isEditMode={isEditMode}
          />
          {isOpenContactModal && (
            <ContactDetailsModal
              entityType={entityType}
              isBoxOffice={isBoxOfficeContact}
              contacts={isBoxOfficeContact ? boxOfficeContacts : generalContacts}
              onClose={() => setIsOpenContactModal(false)}
              trackingData={trackingData}
            />
          )}
        </>
      )}
      <ContactNoAccessModal
        isMobile={isMobile}
        selectedContact={selectedContact}
        onClose={() => setSelectedContact(null)}
      />
    </>
  );
};

export default EntityContactBlock;
