import React, { useMemo } from 'react';
import FeaturedEntities from 'components/Productions/Display/FeaturedEntities';
import Loader from 'components/uiLibrary/Loader';
import SectionBlock from 'components/Globals/SectionBlock';
import queries from 'containers/Artist/queries';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';
import {
  ENTITY_TYPE,
  FEATURED_ARTIST_ENTITIES_SECTIONS,
  PREMIUM_NUDGE_POSITION,
  PREMIUM_NUDGE_VIEW_TYPES,
  TP,
  VALID_QUERY_PARAMS,
} from 'constants/index';

import { ARTIST_PROFILE_INFO_SECTIONS, CHECKLIST_ITEMS, PERFORMANCE_SECTION } from 'constants/consts';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import EmptySectionBlock from 'components/Globals/EmptySectionBlock';
import { createHorizontalSections, createVerticalSections } from 'utils/globals/app';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { artistEditTabValues } from 'containers/Artist/constants';
import usePageContext from 'utils/hooks/usePageContext';
import useProfileCustomizationToggle from 'utils/hooks/useProfileCustomizationToggle';

import classes from './FeaturedArtistEntities.module.scss';

const artistTrackingData = {
  section: SECTIONS.ARTIST_WORKED_WITH,
  component: COMPONENTS.ARTIST_CARD,
};

const orgTrackingData = {
  section: SECTIONS.ORG_COLLABORATED_WITH,
  component: COMPONENTS.ORGANIZATION_CARD,
};

const venueTrackingData = {
  section: SECTIONS.VENUES_PERFORMED_AT,
  component: COMPONENTS.VENUE_CARD,
};

const defaultTrackingData = { section: SECTIONS.RELATED_ENTITIES, component: COMPONENTS.PREVIEW_CARD };

const getTrackingData = type => {
  if (type === ENTITY_TYPE.ARTIST) return artistTrackingData;
  if (type === ENTITY_TYPE.ORGANIZATION) return orgTrackingData;
  if (type === ENTITY_TYPE.VENUE) return venueTrackingData;
  return defaultTrackingData;
};

const FeaturedArtistEntities = ({ entity, type, isEditMode, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const hasPremiumSubscription = entity?.stats?.isPro;
  const { navigate } = usePageContext();

  const { data: preferredEntities, isLoading: preferencesDataLoading } = useQuery(
    queries.getPreferredEntities({
      id: entity?.id,
      entityType: type,
    }),
  );

  const { data: suggestedEntities, isLoading: suggestedDataLoading } = useQuery(
    queries.getSuggestedEntities({
      id: entity?.id,
      entityType: type,
      limit: 6,
      queryConfig: {
        enabled: !preferencesDataLoading && preferredEntities?.data?.length < 1,
      },
    }),
  );

  // TODO - we need to revamp below useMemo
  const { key: sectionKey, unfilledSectionProps, filledSectionLinkProps } = useMemo(() => {
    if (isEditMode) {
      const { getSubPath } = navigate.getLinkProps({ entity, entityType: ENTITY_TYPE.ARTIST, edit: true });
      const emptySectionLinkProps = getSubPath({
        queryParams: {
          tab: artistEditTabValues.schedule,
          [VALID_QUERY_PARAMS.OPEN_MODAL]: PERFORMANCE_SECTION.ADD_PERFORMANCE,
        },
      });

      const CURRENT_SELECTED_ITEM = {
        [ENTITY_TYPE.ARTIST]: {
          checklist: CHECKLIST_ITEMS.ARTISTS_WORKED_WITH,
          emptySectionProps: {
            icon: 'account_circle',
            focusedSection: ARTIST_PROFILE_INFO_SECTIONS.PERFORMED_WITH_ARTIST,
            linkProps: emptySectionLinkProps,
          },
        },
        [ENTITY_TYPE.VENUE]: {
          checklist: CHECKLIST_ITEMS.VENUES_PERFORMED,
          emptySectionProps: {
            icon: 'location',
            focusedSection: ARTIST_PROFILE_INFO_SECTIONS.PERFORMED_AT_VENUE,
            linkProps: emptySectionLinkProps,
          },
        },
        [ENTITY_TYPE.ORGANIZATION]: {
          checklist: CHECKLIST_ITEMS.ORGANIZATIONS_WORKED_WITH,
          emptySectionProps: {
            icon: 'agency_unselected',
            focusedSection: ARTIST_PROFILE_INFO_SECTIONS.PERFORMED_WITH_ORG,
            linkProps: emptySectionLinkProps,
          },
        },
      };

      const unfilledSection = CURRENT_SELECTED_ITEM[type].emptySectionProps;

      return {
        key: CURRENT_SELECTED_ITEM[type].checklist,
        unfilledSectionProps: unfilledSection,
        filledSectionLinkProps: getSubPath({
          queryParams: {
            tab: artistEditTabValues.personal,
            [VALID_QUERY_PARAMS.OPEN_MODAL]: unfilledSection?.focusedSection,
          },
          scrollTo: unfilledSection?.focusedSection,
        }),
      };
    }
    return {};
  }, [entity, isEditMode, navigate, type]);

  const {
    data: profileCustList,
    isLoading: isProfileCustLoading,
    onToggle: handleShowHide,
  } = useProfileCustomizationToggle({
    entity,
    key: sectionKey,
    enabled: !!isEditMode,
  });

  if (
    preferencesDataLoading ||
    (isEditMode && isProfileCustLoading) ||
    (suggestedDataLoading && !preferredEntities?.data?.length)
  ) {
    return <Loader />;
  }

  if (!preferredEntities?.data?.length && !suggestedEntities?.data?.length) {
    if (isEditMode) {
      return (
        <EmptySectionBlock
          sectionProps={{
            ...sectionProps,
            title: t(FEATURED_ARTIST_ENTITIES_SECTIONS[type]?.displayTitle, { entityName: entity?.name }),
            emptySections: [
              ...createVerticalSections({
                icon: <SpriteIcon icon={unfilledSectionProps.icon} size={22} />,
                skipTracking: true,
              }),
              ...createHorizontalSections([
                {
                  title: t(`${TP}.FN_ADD_DATA_TO_SECTION`, { section: t(`PERFORMANCES`) }),
                  linkProps: unfilledSectionProps?.linkProps,
                  trackingData: {
                    ...getTrackingData(type),
                    subComponent: SUB_COMPONENTS.ADD_PRODUCTION_CTA,
                  },
                },
              ]),
            ],
            ...(!hasPremiumSubscription && {
              premiumSection: {
                type: PREMIUM_NUDGE_VIEW_TYPES.CONCISE,
                enabled: true,
                placement: PREMIUM_NUDGE_POSITION.TOP,
                nudgeSourceType: FEATURED_ARTIST_ENTITIES_SECTIONS[type]?.nudgeType,
                trackingData: getTrackingData(type),
              },
            }),
          }}
        />
      );
    }
    return null;
  }

  return (
    <SectionBlock
      className={classes.sectionWrapper}
      styles={{
        title: classes.sectionWrapper__title,
        ...(isEditMode &&
          !hasPremiumSubscription && {
            root: classes.sectionWrapper__premiumSection,
          }),
      }}
      title={t(FEATURED_ARTIST_ENTITIES_SECTIONS[type]?.displayTitle, { entityName: entity?.name })}
      {...{
        ...sectionProps,
        ...(isEditMode && {
          editMode: {
            ...sectionProps?.editMode,
            isCustomization: true,
            linkProps: filledSectionLinkProps,
            trackingData: getTrackingData(type),
          },
          ...(!hasPremiumSubscription && {
            premiumSection: {
              type: PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
              enabled: true,
              nudgeSourceType: FEATURED_ARTIST_ENTITIES_SECTIONS[type]?.nudgeType,
              trackingData: getTrackingData(type),
            },
          }),
          showHideProps: {
            enabled: true,
            onToggle: handleShowHide,
            value: profileCustList?.[sectionKey]?.value,
            specialPermission: { permission: hasPremiumSubscription },
            popupSource: FEATURED_ARTIST_ENTITIES_SECTIONS[type]?.nudgeType,
            trackingData: getTrackingData(type),
          },
        }),
      }}
    >
      <FeaturedEntities
        data={preferredEntities?.data?.length > 0 ? preferredEntities?.data : suggestedEntities?.data}
        entityType={type}
        trackingData={getTrackingData(type)}
      />
    </SectionBlock>
  );
};

export default FeaturedArtistEntities;
